import styled from "styled-components";
import { BLACK, PURPLE, BEIGE } from "../../constants/colors";
import { SM, MD, LG } from "../../constants/mediaQueries";
import {Nav, NavLink, NavItem} from "react-bootstrap";


export const TabLinks = styled(Nav)`
  position: static;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;

  @media (max-width: ${MD}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TabOption = styled(NavItem)`
  position: relative;
  margin: 0.5rem 30px;
  
  &.nav-item:hover  a{
    background: ${PURPLE};
    border: 1px solid ${PURPLE};
    // animation: brinquito 150ms cubic-bezier(.50,.70,.50,.30);
  }

  @keyframes brinquito {
    0% {
      bottom: 0;
    }
    50% {
      bottom: 0.75rem;
    }
    100% {
      bottom: 0;
    }
  }
`;

export const TabLink = styled(NavLink)`
&.nav-link {
  text-decoration: none;
  color: ${BEIGE}!important;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
  margin: 0;
  border: 1px solid ${BEIGE};
  border-radius: 25px;
  padding: 0 20px;
  position: relative;
}
  &.nav-link.active {
    color:  ${BEIGE};
    background: ${PURPLE};
    border: 1px solid ${PURPLE};  
  }

  @media (max-width: ${MD}) {
    font-size: 1.5rem;
  }
`;

export const SectionTitle = styled.h1`
  font-size: 7rem;
  position: fixed;
  text-align: right;
  right: 0;
  top: 0;
  padding-top: 0.25rem;
  color: ${PURPLE};
  font-weight: bold;
  z-index: 1000;

  @media (max-width: ${LG}) {
    mix-blend-mode: difference;
  }

  @media (max-width: ${LG}) {
    margin-top: 0;
    max-width: 85%;
  }
  @media (max-width: ${MD}) {
    font-size: 5rem;
  }
  @media (max-width: ${SM}) {
    font-size: 4rem;
    max-width: 100%;
  }

  @media (max-width: 350px) {
    font-size: 3rem;
  }
`;

export const FutureEventsStyled = styled.div`
  ${props => props.hide ? 'display: none;' : ''}
  width: 100%;
  height: 100%;
  color: white;
  background-color: ${BLACK};
  padding-top: 15px;
  font-size: 18px;
  text-transform: uppercase;
  overflow: hidden;

  .tab-content {
    margin-top: 4rem;
    margin-bottom: 5rem;
    min-height: 100vh;
  }

  @media (max-width: ${SM}) {
    .tab-content {
      margin-top: 0;
    }
  }
`;

