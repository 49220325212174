import React, { useEffect } from "react";
import { EventScrollWrapper } from "./style";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imagesLoaded from "imagesloaded";

export const GalleryScroll = (props) => {
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const images = gsap.utils.toArray("img");
    const loader = document.querySelector(".loader--text");
    const updateProgress = (instance) =>
      (loader.textContent = `${Math.round(
        (instance.progressedCount * 100) / images.length
      )}%`);

    const showDemo = () => {
      document.body.style.overflow = "auto";
      document.scrollingElement.scrollTo(0, 0);
      gsap.to(document.querySelector(".loader"), { autoAlpha: 0 });
      if (props.onLoaded) props.onLoaded();

      gsap.utils.toArray("section").forEach((section, index) => {
        const w = section.querySelector(".wrapper");
        let x, xEnd;
        if (window.innerWidth <= 640) {
          if (index % 2) {
            x = w.scrollWidth * 1.7 ;
            xEnd = (w.scrollWidth - section.offsetWidth) * -1
          } else {  
            x = -section.offsetWidth * 24;
            xEnd = 0;
          }
          
        } else if (window.innerWidth <= 768) {
          if (index % 2) {
            x = w.scrollWidth * 1.1 ;
            xEnd = (w.scrollWidth - section.offsetWidth) * -1
          } else {  
            x = -section.offsetWidth * 10;
            xEnd = 0;
          }
        } else if (window.innerWidth <= 1024) {
          if (index % 2) {  
            x = w.scrollWidth;
            xEnd = (w.scrollWidth - section.offsetWidth) * -1
          } else {
            x = -section.offsetWidth * 10;
            xEnd = 0;
          }
        } else {
          if (index % 2) {  
            x = w.scrollWidth;
            xEnd = (w.scrollWidth - section.offsetWidth) * -1
          } else {
            x = -section.offsetWidth * 7;
            xEnd = 0;
          }
        }
        
        gsap.fromTo(
          w,
          { x },
          {
            x: xEnd,
            scrollTrigger: {
              trigger: section,
              scrub: 0.5,
            },
          }
        );
      });
    };

    imagesLoaded(images).on("progress", updateProgress).on("always", showDemo);
  }, [props.eventInfo.slug]);
  
  return (
    <EventScrollWrapper>
      <script src="https://unpkg.com/imagesloaded@5/imagesloaded.pkgd.js"></script>
      <div className="loader df aic jcc">
        <div>
          <h1 className="visible">Loading</h1>
          <h2 className="loader--text visible">0%</h2>
        </div>
      </div>
      <div className="demo-wrapper">
        <section className="demo-gallery">
          <ul className="wrapper">
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_1?.asset.alt}
                src={props.eventInfo?.image_1?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_2?.asset.alt}
                src={props.eventInfo?.image_2?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_3?.asset.alt}
                src={props.eventInfo?.image_3?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_4?.asset.alt}
                src={props.eventInfo?.image_4?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_5?.asset.alt}
                src={props.eventInfo?.image_5?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_6?.asset.alt}
                src={props.eventInfo?.image_6?.asset.url}
                className="image_1"
              />
            </li>
          </ul>
        </section>

        <section className="demo-gallery">
          <ul className="wrapper">
            <li className="image-container">
              <img
                alt={props.eventInfo?.image_1?.asset.alt}
                src={props.eventInfo?.image_1?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                height="120px"
                alt={props.eventInfo?.image_2?.asset.alt}
                src={props.eventInfo?.image_2?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                height="120px"
                alt={props.eventInfo?.image_3?.asset.alt}
                src={props.eventInfo?.image_3?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                height="120px"
                alt={props.eventInfo?.image_4?.asset.alt}
                src={props.eventInfo?.image_4?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                height="120px"
                alt={props.eventInfo?.image_5?.asset.alt}
                src={props.eventInfo?.image_5?.asset.url}
                className="image_1"
              />
            </li>
            <li className="image-container">
              <img
                height="120px"
                alt={props.eventInfo?.image_6?.asset.alt}
                src={props.eventInfo?.image_6?.asset.url}
                className="image_1"
              />
            </li>
          </ul>
        </section>
      </div>
    </EventScrollWrapper>
  );
};
