import styled from 'styled-components'
import { BLACK } from '../../constants/colors';
import { LG, XL, SM } from '../../constants/mediaQueries';

export const EventScrollWrapper = styled.div`
:root { font-size: 16px }
@media (max-width: 500px) { :root { font-size: 14px } }

&::after {
  box-shadow: 0px 202px 22px -86px ${BLACK} inset;
  -webkit-box-shadow: 0px 202px 22px -86px ${BLACK} inset;
  -moz-box-shadow: 0px 202px 22px -86px ${BLACK} inset;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;   
}

@media (max-width: ${SM}) {
  &::after {
    box-shadow: 0px 152px 22px -86px ${BLACK} inset;
    -webkit-box-shadow: 0px 152px 22px -86px ${BLACK} inset;
    -moz-box-shadow: 0px 152px 22px -86px ${BLACK} inset;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;   
  }
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background: #87cd33;
  color: white;
}

body {
  overflow: hidden;
}

h1 { font-size: 5rem }
h2 { font-size: 2rem }

.image-container{
  width: 400px;


  @media (max-width: ${LG}) {
    width: 300px;
  }

  @media (max-width: ${SM}) {
    width: 220px;
  }
}

img {
  width: 100%;
  height: 350px;
  object-fit: cover;

  @media (max-width: ${LG}) {
    height: 340px;
  }

  @media (max-width: ${SM}) {
    height: 250px;
  }
}

ul {
  padding-left: 1rem;
  list-style: none;
}

li {
  flex-shrink: 0;
  width: clamp(500px, 60vw, 800px);
  padding-right: 0.5rem;
}

header {height: 100vh}
footer {height: 50vh}

:any-link { color: #4e9815; }

.df {display: flex}
.aic {align-items: center}
.jcc {justify-content: center}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  color: white;
  display: none;
}

.visible{
  display: none;
}

.demo-wrapper {
  overflow-x: hidden;
  position: relative;

  &::after {
    box-shadow: 0px -122px 22px -86px ${BLACK} inset;
    -webkit-box-shadow: 0px -122px 22px -86px ${BLACK} inset;
    -moz-box-shadow: 0px -122px 22px -86px ${BLACK} inset;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;    
  }

}

.wrapper {
  display: flex;
}


.demo-gallery:not(.last) {
  padding-bottom: 0.5rem;
}

.demo-text .text {
  font-size: clamp(8rem, 15vw, 16rem);
  line-height: 1;
  font-weight: 900;
}


`;