import React from "react";
import { BackToHome } from "./style";


export const BackButton = (props) => {

  return (
    <BackToHome color={props.color} to="/" forceBlend={props.forceBlend}>BACK</BackToHome>
  );
};
