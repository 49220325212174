import React from "react";
import { LoadingWrapper, LoadingContainer, LoadingBar } from "./style";
import isotipo from "../../images/isotipo.svg";

export const LoadingScreen = (props) => {
  return (
  <LoadingWrapper>
    <LoadingContainer>
      <LoadingBar/>
    </LoadingContainer>
    <h1>LOADING</h1>
    <img src={isotipo} alt="Take Flight" />
  </LoadingWrapper>
  );
};
