import React from "react";
import { EventPageWrapper, EventDetails, EventData, Column, EventTitle } from "./style";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LoadingScreen } from "../LoadingScreen";


gsap.registerPlugin(ScrollTrigger);

export const EventInformation = ({ eventInfo, key }) => {
  
  return (
    <div id={eventInfo.title}>
      {!eventInfo ? (
        <LoadingScreen/>
      ) : (
        <EventPageWrapper>
          <EventDetails>
            <EventTitle>{eventInfo.title}</EventTitle>
            <Column>
              <EventData> CITY: <span>{eventInfo.city}</span></EventData>
              <EventData> LOCATION: <span>{eventInfo.location}</span></EventData>
              <EventData> DATE: <span>{eventInfo.dates}</span></EventData>
            </Column>
            <Column justify={true}><EventData>{eventInfo.description}</EventData></Column>
          </EventDetails>
        </EventPageWrapper>
      )}
    </div>
  );
};
