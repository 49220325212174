import styled from "styled-components";
import { BEIGE, BLACK } from "../../constants/colors";
import { SM } from "../../constants/mediaQueries";

export const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  color: ${BEIGE};
  background-color: ${BLACK};
  font-size: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 20px;
    @media (max-width: ${SM}) {
      font-size: 40px;
    }
  }
  img {
    width: 100px;
    color: ${BEIGE};
    @media (max-width: ${SM}) {
      width: 60px;
    }
  }
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  margin: auto;
`;
export const LoadingBar = styled.div`
  height: 5px;
  background: #f4f2f0;
  transition: width 500ms;
  animation: loading 1s ease-out;

  @keyframes loading {
    from {
      width: 0%;
    }
    to {
      width: 95%;
    }
  }
`;
