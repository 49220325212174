import { Link } from "gatsby";
import styled from "styled-components";
import { BEIGE } from "../../../constants/colors";
import { SM, LG } from "../../../constants/mediaQueries";

export const BackToHome = styled(Link)`
  font-size: 2rem;
  font-weight: 600;
  position: fixed;
  top: 1.5rem;
  left: 40px;
  height: 60px;
  display: block;
  color: ${props => props.color || BEIGE};
  cursor: pointer;
  z-index: 2000;
  @media (max-width: ${LG}) {
    mix-blend-mode: difference;
  }
  text-decoration: none;
  &:hover {
    text-decoration: line-through;
    color: ${props => props.color || BEIGE};
  }
  &:active {
    color: ${props => props.color || BEIGE};
  }

  @media (max-width: ${SM}) {
    display: none;
  }

  ${props => props.forceBlend ? ' mix-blend-mode: difference;' : '' }
`;
