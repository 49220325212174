import React, { useState, useEffect } from "react";
import { FutureEventsStyled, TabLinks, TabLink, TabOption, SectionTitle } from "./style";
import { EventInformation } from "../EventInformation";
import { GalleryScroll } from "../GalleryScroll";
import Tab from "react-bootstrap/Tab";
import {TabContainer} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BackButton } from "../Menu/BackButton";
import { LoadingScreen } from "../LoadingScreen";

import sanityClient from "../../client.js";
import { FloatingMenu } from "../Menu/FloatingMenu";
import { Footer } from "../Footer";
import { GREEN, PURPLE, PURPLEOPPOSITE } from "../../constants/colors";

export const UpcomingEventsComponent = () => {
  const isBrowser = () => typeof window !== "undefined";
  const [allEvents, setAllEvents] = useState(null);
  const [galleryLoaded, setGalleryLoaded] = useState(false);
  const [key, setKey] = useState(allEvents?.[0]?.link);


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "future-events"]{
        title,
        link,
        eventAlias,
        location,
        city,
        dates,
        description,
        url,
        image_1{
          asset ->{
            _id,
            url
          }
        },
        image_2{
          asset ->{
            _id,
            url
          }
        },
        image_3{
          asset ->{
            _id,
            url
          }
        },
        image_4{
          asset ->{
            _id,
            url
          }
        },
        image_5{
          asset ->{
            _id,
            url
          }
        },
        image_6{
          asset ->{
            _id,
            url
          }
        },
      }`
      )
      .then((data) => setAllEvents(data))
      .catch(console.error);
  }, []);

  const selectTab = function (newKey) {
    window.history.pushState(null, null, `/events/#${newKey}`);
    setKey(newKey);
  }

  const setDefaultTab = function () {
    if (isBrowser() && window.location.hash.replace(/#/g, "").trim() === "") {
      return allEvents?.[0]?.link;
    } else {
      const urlKey = isBrowser() && window.location.hash.replace(/#/g, "").trim()
      if (allEvents.map(e => e.link).includes(urlKey)) {
        return urlKey;
       } else {
        window.history.pushState(null, null, `/events`);
        return allEvents?.[0]?.link;
       } 
    }
  }

  return (
    <div>
      { (!galleryLoaded || !allEvents) && 
        <LoadingScreen/>
      }
      {allEvents &&
        <FutureEventsStyled hide={!galleryLoaded || !allEvents}>
          <SectionTitle>UPCOMING EVENTS</SectionTitle>
          <TabContainer
            activeKey={key}
            defaultActiveKey={setDefaultTab}
          >
            <Tab.Content>
              {allEvents?.map((event, index) => {
                return (
                  <Tab.Pane 
                    key={index}
                    eventKey={event.link}
                    title={event.title.trim()}
                  >
                    <GalleryScroll eventInfo={event} key={key} onLoaded={() => setGalleryLoaded(true)}/>
                  </Tab.Pane>
                );
              })}
               <TabLinks 
                  onSelect={(selectedKey) => selectTab(selectedKey)}
                >
                {allEvents?.map((event, index) => {
                  return (
                    <TabOption key={index}>
                      <TabLink eventKey={event.link}>{event.title}</TabLink>
                    </TabOption>
                  );
                })}
              </TabLinks>
              {allEvents?.map((event, index) => {
                  return (
                    <Tab.Pane
                      key={index}
                      eventKey={event.link}
                      title={event.title.trim()}
                    >
                      <EventInformation eventInfo={event} />
                    </Tab.Pane>
                  );
                })}
            </Tab.Content>
          </TabContainer>
          <BackButton color={PURPLE}/>
          <FloatingMenu color={PURPLE} showTicketsButton={true}/>
          <Footer/>
        </FutureEventsStyled>
      }
    </div>
  );
};
