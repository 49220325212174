import styled from "styled-components";
import { BLACK, PURPLE, BEIGE } from "../../constants/colors";
import { LG, MD, SM } from "../../constants/mediaQueries";

export const EventDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  text-transform: uppercase;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  padding: 2rem;

  @media (max-width: ${MD}) {
    flex-direction: column;
  }
`;

export const EventData = styled.p`
  margin: 0;
  
`;
export const Column = styled.div`
  min-width: 50%;
  ${props => props.justify ? 'text-align: justify;' : ''}
  @media (max-width: ${MD}) {
    margin-bottom: 2rem;
  }
`;


export const EventTitle = styled.div`
  display: none;
  font-size: 2rem;
  margin-bottom: 1.75rem;
  margin-top: 1.5rem;
  @media (max-width: ${MD}) {
    display: block;
  }
`;

export const EventPageWrapper = styled.div`
  width: 100vw;
  height: auto;
  background-color: ${BLACK};
  color: ${BEIGE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .EventDetails {
    margin: 15px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      text-transform: uppercase;
      font-size: 54px;
      margin-bottom: 50px;
      font-weight: bold;
      text-align: center;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
      -webkit-font-smoothing: antialiased;
      @media (max-width: 640px) {
        font-size: 32px;
      }
    }

    p {
      width: 90vw;
      padding-bottom: 60px;
      font-weight: lighter;
      font-style: lighter;
      font-size: 24px;
      text-align: center;
      @media (max-width: ${LG}) {
        font-size: 32px;
        width: 90vw;
      }
      @media (max-width: ${LG}) {
        font-size: 24px;
      }
      @media (max-width: ${LG}) {
        font-size: 18px;
      }
    }

    .titleButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      @media (max-width: ${LG}) {
        flex-direction: column;
      }
      h4 {
        margin-left: 50px;
        font-weight: bold;
        font-size: 24px;
        span {
          color: ${BEIGE};
        }
        @media (max-width: ${LG}) {
          text-align: center;
          font-size: 20px;
          margin-bottom: 20px;
          margin-left: 0;
        }
      }
    }
  }
`;

